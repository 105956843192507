<template>
  <div class="card-rating">
    <div class="card-rating__score-container">
      <span class="card-rating__score__icon">
        <i class="icon-star_full" />
      </span>
      <span>{{ rating.score }}</span>
      <span class="card-rating__score-container__divider">|</span>
      <span>{{ rating.serviceTitle }}</span>
    </div>
    <div class="card-rating__info-container">
      <span class="card-rating__info-container__name">{{ rating.userName }}</span>
      <span class="card-rating__info-container__time">{{ rating.orderTime }}</span>
    </div>
    <div class="card-rating__img-container">
      <img
        v-for="(img, index) in rating.imgList"
        :key="index"
        class="card-rating__img-container__img swiper-lazy"
        :data-src="img"
        :src="lazyImg ? require('@/assets/image/article/default-cover.png') : img"
        loading="lazy"
      >
    </div>
    <div class="card-rating__score-desc">
      <p>
        <template v-for="(desc, index) in rating.scoreDesc">
          {{ desc }}
          <br
            v-if="index < rating.scoreDesc.length - 1"
            :key="index"
          >
        </template>
      </p>
    </div>
    <div class="card-rating__score-tag-container">
      <v-chip
        v-for="(tag, tagIndex) in rating.tagList"
        :key="tagIndex"
        class="card-rating__score-tag-container__tag"
      >
        {{ tag.name }}
      </v-chip>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    rating: {
      type: Object,
      default: () => {}
    },
    lazyImg: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.card-rating {
  $cardBorderRadius: 12px;
  border-radius: $cardBorderRadius;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  padding: 26px 28px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: $whiteColor;
  &:hover {
    cursor: pointer;
    box-shadow: 0 9px 12px 0 rgba(0, 0, 0, 0.16);
    transform: translateY(-3px);
    transition: ease-in-out 200ms;
  }
  &__img-container {
    margin-bottom: 10px;
    &__img {
      width: 100px;
      height: 100px;
      display: inline-block;
      margin-right: 8px;
      border-radius: 4px;
      background-position: center;
      object-fit: cover;
      background-repeat: no-repeat;
      background-color: $lightGrayColor;
    }
  }
  &__score-container {
    margin-bottom: 10px;
    &__divider {
      display: inline-block;
      margin: 0 8px;
    }
  }
  &__score-desc {
    p {
      @include lineClamp(3);
    }
    margin-bottom: 20px;
  }
  &__score__icon {
    color: $yellowColor;
  }
  &__info-container {
    margin-bottom: 10px;
    &__name {
      font-weight: bold;
      font-size: pxTorem(16px);
      margin-right: 8px;
    }
    &__time {
      font-size: pxTorem(14px);
      color: #999999;
    }
  }
  &__score-tag-container {
    margin-top: auto;
    &__tag {
      margin-right: 8px;
      margin-bottom: 8px;
    }
  }
}
@media screen and (max-width: $mobileWidth) {
  .card-rating {
    padding: 16px 12px 6px;
    &__img-container {
      &__img {
        width: 75px;
        height: 75px;
        margin-right: 6px;
      }
    }
    &__score-tag-container {
      &__tag {
        margin-bottom: 12px;
      }
    }
  }
}
</style>
