<template>
  <div
    id="page-homepage"
    class="page-homepage"
  >
    <div
      ref="bannerSwiper"
      v-swiper:bannerSwiper="banner.sliderOptions"
      class="homepage-banner-swiper"
      @slideChange="bannerSlideChangeHandler('bannerSwiper', 'banner')"
    >
      <div class="swiper-wrapper">
        <div
          v-for="banner in banner.list"
          :key="banner.Id"
          class="swiper-slide homepage-banner-swiper__slide"
          @click="clickBannerHandler(banner)"
        >
          <img
            class="homepage-banner-swiper__image"
            :src="banner.ImageUri"
            :alt="banner.title"
          >
        </div>
      </div>
      <div
        v-if="showBannerSwiperArrow"
        slot="pagination"
        class="swiper-pagination swiper-pagination-custom"
      >
        <span
          v-for="(dot, index) in banner.list"
          :key="dot.Id"
          class="swiper-pagination-btn"
          :class="{ 'active': index === banner.activeIndex, 'one-child': banner.list.length === 1 }"
          @click="clickBannerPaginationHandler(index)"
        >
          <span class="swiper-pagination-btn__text">{{ banner.list[index].title }}</span>
        </span>
      </div>
      <div
        v-if="showBannerSwiperArrow"
        :slot="showBannerSwiperArrow ? 'button-prev' : ''"
        class="swiper-arrow-controls inside"
      >
        <v-btn
          class="prev btn banner-swiper-prev-btn"
          fab
        >
          <i class="icon icon-arrow-left-bold" />
        </v-btn>
      </div>
      <div
        v-if="showBannerSwiperArrow"
        :slot="showBannerSwiperArrow ? 'button-next' : ''"
        class="swiper-arrow-controls inside"
      >
        <v-btn
          class="next btn banner-swiper-next-btn"
          fab
        >
          <i class="icon icon-arrow-right-bold" />
        </v-btn>
      </div>
    </div>
    <v-skeleton-loader
      v-show="!canShowBannerSwiper"
      class="homepage-loader__banner"
      type="image"
    />
    <section
      v-if="recommendService.list && recommendService.list.length"
      class="recommend-service-section"
    >
      <SectionTitle
        class="recommend-service-section__title"
        title="推薦服務"
      />
      <GroupServiceList
        class="recommend-service-section__service-list"
        :service-list="recommendService.list"
        service-parent-type-id="star"
      />
      <div
        v-if="recommendService.showSeeMoreBtn && recommendService.list"
        class="recommend-service-section__see-more"
      >
        <v-btn
          class="recommend-service-section__see-more__btn"
          outlined
          color="primary"
          @click="goServiceHandler"
        >
          查看更多
          <i class="icon icon-arrow-right-bold" />
        </v-btn>
      </div>
    </section>
    <section
      v-else
      class="homepage-loader__card-container"
    >
      <v-skeleton-loader
        v-for="x in 8"
        :key="x"
        class="homepage-loader__card-container__card"
        type="card, list-item-three-line"
      />
    </section>
    <section
      v-if="customerRating.list.length"
      class="customer-rating-section"
    >
      <SectionTitle
        class="customer-rating-section__title"
        title="用戶好評"
      />
      <div class="customer-rating-card-container card-swiper-container">
        <v-lazy
          :options="{ rootMargin: '0px' }"
          transition="fade-transition"
        >
          <div
            id="customer-rating-swiper"
            ref="customerRatingSwiper"
            v-swiper:customerRatingSwiper="customerRating.sliderOptions"
            class="swiper"
            @slideChange="bannerSlideChangeHandler('customerRatingSwiper', 'customerRating')"
          >
            <div class="swiper-wrapper">
              <div
                v-for="rating in customerRating.list"
                :key="rating.id"
                class="swiper-slide customer-rating-swiper-silde"
                @click="goServiceComment(rating)"
              >
                <CardRating
                  class="customer-rating-card"
                  :rating="rating"
                  lazy-img
                />
              </div>
            </div>
            <div
              :slot="showCustomerSwiperArrow ? 'button-prev' : ''"
              class="swiper-arrow-controls rating"
            >
              <v-btn
                class="prev btn rating-swiper-prev-btn"
                fab
              >
                <i class="icon icon-arrow-left-bold" />
              </v-btn>
            </div>
            <div
              :slot="showCustomerSwiperArrow ? 'button-next' : ''"
              class="swiper-arrow-controls rating"
            >
              <v-btn
                class="next btn rating-swiper-next-btn"
                fab
              >
                <i class="icon icon-arrow-right-bold" />
              </v-btn>
            </div>
          </div>
        </v-lazy>
      </div>
    </section>
    <section class="smartdaily-intro-section">
      <div class="smartdaily-intro__container">
        <div
          v-for="(info, index) in smartdailyInfo.list"
          :key="index"
          class="smartdaily-intro"
        >
          <v-lazy>
            <div
              class="smartdaily-intro__img"
              data-aos="zoom-in"
            >
              <img
                loading="lazy"
                :alt="info.title"
                :src="require(`~/assets/image/homepage/${info.img}.png`)"
              >
            </div>
          </v-lazy>
          <div class="smartdaily-intro__title">
            <p>{{ info.title }}</p>
          </div>
          <div class="smartdaily-intro__desc">
            <p
              v-for="(desc, descIndex) in info.descList"
              :key="descIndex"
            >
              {{ desc }}
            </p>
          </div>
        </div>
      </div>
    </section>
    <section
      v-if="recommendedArticle.list.length"
      class="recommend-article-section"
    >
      <SectionTitle
        class="recommend-article-section__title"
        title="推薦文章"
      />
      <div class="recommend-article-section__articles-container">
        <ArticleCard
          v-for="article in recommendedArticle.list"
          :key="`${'recommend-article' + article.Id}`"
          class="recommend-article-section__article"
          :content="article"
          :display="isMobile ? 'column' : 'row'"
          :img-width="isMobile ? '' : '250px'"
          :img-height="isMobile ? '' : '120px'"
          :aspect-ratio="isMobile ? recommendedArticle.imageAspectRatio : 0"
          :show-content="false"
          :show-title-on-img="false"
          @click.native="goArticlePage(article)"
        />
      </div>
      <div
        v-if="recommendedArticle.showSeeMoreBtn"
        class="recommend-article-section__see-more"
      >
        <v-btn
          class="recommend-article-section__see-more__btn"
          outlined
          color="primary"
          @click="goInformationStationHandler"
        >
          查看更多
          <i class="icon icon-arrow-right-bold" />
        </v-btn>
      </div>
    </section>
    <div
      class="homepage-section-spacer"
      :class="{ 'reduce-mt': !recommendedArticle.showSeeMoreBtn }"
    />
    <section class="recommend-share-section">
      <SectionTitle
        class="recommend-share-section__title"
        title="真實推薦分享"
      />
      <div class="recommend-share-card-container card-swiper-container">
        <div
          id="recommend-share-swiper"
          ref="recommendShareSwiper"
          v-swiper:recommendShareSwiper="recommendShare.sliderOptions"
          class="swiper"
        >
          <div class="swiper-wrapper">
            <div
              v-for="(sharePost, index) in recommendShare.list"
              :key="index"
              class="swiper-slide recommend-share-swiper-silde"
            >
              <v-lazy>
                <CardBlogShare
                  class="recommend-share-card"
                  :share-post="sharePost"
                  @click.native="openShareArticleHandler(sharePost)"
                />
              </v-lazy>
            </div>
            <div class="swiper-slide fake-slide" />
          </div>
          <div
            slot="button-prev"
            class="swiper-arrow-controls recommend-share"
          >
            <v-btn
              class="prev btn recommend-share-swiper-prev-btn"
              fab
            >
              <i class="icon icon-arrow-left-bold" />
            </v-btn>
          </div>
          <div
            slot="button-next"
            class="swiper-arrow-controls recommend-share"
          >
            <v-btn
              class="next btn recommend-share-swiper-next-btn"
              fab
            >
              <i class="icon icon-arrow-right-bold" />
            </v-btn>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SectionTitle from '@/components/ComponentSectionTitle'
import GroupServiceList from '@/components/GroupServiceList'
import CardRating from '@/components/Card/CardRating'
import CardBlogShare from '@/components/Card/CardBlogShare'
import ArticleCard from '@/components/PageComponent/ArticleSection/ArticleCard'
import recommendShareBlog from '@/static/recommendShareBlog.json'
export default {
  components: {
    SectionTitle,
    GroupServiceList,
    CardRating,
    CardBlogShare,
    ArticleCard
  },
  data () {
    return {
      title: '智樂家-家電清洗、居家清潔、專業到府服務，一次解決家的大小事',
      image: null,
      isLoading: true,
      // 首頁banner
      banner: {
        list: [],
        sliderOptions: {
          loop: true,
          watchOverflow: true,
          touchEventsTarget: 'wrapper',
          preloadImages: true,
          autoplay: {
            delay: 8000,
            disableOnInteraction: false
          },
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
            type: 'custom'
          },
          navigation: {
            nextEl: '.banner-swiper-next-btn',
            prevEl: '.banner-swiper-prev-btn'
          }
        },
        activeIndex: 0,
        loadDone: false
      },
      // 推薦服務
      recommendService: {
        list: null,
        showSeeMoreBtn: false
      },
      // 用戶好評
      customerRating: {
        list: [],
        sliderOptions: {
          autoplay: {
            delay: 5000,
            disableOnInteraction: false
          },
          loop: true,
          loopedSlides: 20,
          slidesPerColumnFill: 'row',
          spaceBetween: 40,
          slidesPerView: 'auto',
          preloadImages: false,
          lazy: true,
          watchSlidesVisibility: true,
          breakpoints: {
            768: {
              spaceBetween: 16
            }
          },
          navigation: {
            nextEl: '.rating-swiper-next-btn',
            prevEl: '.rating-swiper-prev-btn'
          }
        }
      },
      // 固定資訊區塊
      smartdailyInfo: {
        list: [
          {
            img: 'service-quality',
            title: '專業服務品質',
            descList: [
              '智生活嚴選',
              '專業廠商與技師'
            ]
          },
          {
            img: 'standard-process',
            title: '安心標準流程',
            descList: [
              '真實評價',
              '透明價格',
              '保固承諾'
            ]
          },
          {
            img: 'easy-order',
            title: '便利預約訂購',
            descList: [
              '快速下單',
              '即時預約',
              '多元金流'
            ]
          },
          {
            img: 'user-experience',
            title: '尊榮會員體驗',
            descList: [
              '300+人服務團隊',
              '專屬會員優惠'
            ]
          }
        ]
      },
      // 推薦文章
      recommendedArticle: {
        list: [],
        showSeeMoreBtn: true,
        imageAspectRatio: 1000 / 480,
        // 文章取值主分類id
        aritcleMainTypeIds: [39, 69]
      },
      // 真實推薦分享
      recommendShare: {
        list: [],
        sliderOptions: {
          slidesPerColumnFill: 'row',
          spaceBetween: 24,
          loop: false,
          slidesPerView: 'auto',
          breakpoints: {
            768: {
              spaceBetween: 16
            }
          },
          navigation: {
            nextEl: '.recommend-share-swiper-next-btn',
            prevEl: '.recommend-share-swiper-prev-btn'
          }
        }
      },
      userDefaultCommunity: {},
      bannerSwiperMouseoverListener: null,
      bannerSwiperMouseleaveListener: null,
      customerRatingSwiperMouseoverListener: null,
      customerRatingSwiperMouseleaveListener: null
    }
  },
  head () {
    return {
      title: this.title,
      titleTemplate: null,
      meta: [
        { hid: 'title', property: 'title', content: this.title },
        { hid: 'og:url', property: 'og:url', content: this.webBaseUrl + this.routeFullPath },
        { hid: 'og:title', property: 'og:title', content: this.title },
        { hid: 'twitter:url', property: 'twitter:url', content: this.webBaseUrl + this.routeFullPath },
        { hid: 'twitter:title', property: 'twitter:title', content: this.title }
      ],
      link: this.bannerListPreload
    }
  },
  computed: {
    ...mapGetters(['isMobile']),
    canShowBannerSwiper () {
      return this.banner.loadDone
    },
    fetchAllDone () {
      return !this.isLoading && !this.$fetchState.pending
    },
    showBannerSwiperArrow () {
      return this.banner.list.length > 1
    },
    showCustomerSwiperArrow () {
      return this.customerRating.list.length > 3
    },
    processClient () {
      return process.client
    },
    bannerListPreload () {
      if (this.banner.list.length) {
        return this.banner.list.map(banner => {
          return {
            rel: 'preload',
            as: 'image',
            href: banner.ImageUri
          }
        })
      }
      return []
    }
  },
  watch: {
    fetchAllDone (val) {
      if (val) {
        this.$nextTick(() => {
          const addHoverListener = (refKey) => {
            const ref = this.$refs[refKey]?.$el
            if (!ref) return
            this[`${refKey}MouseoverListener`] = () => {
              this.$refs[refKey]?.swiper?.autoplay?.stop()
            }
            this[`${refKey}MouseleaveListener`] = () => {
              this.$refs[refKey]?.swiper?.autoplay?.start()
            }
            ref.addEventListener('mouseover', this[`${refKey}MouseoverListener`])
            ref.addEventListener('mouseleave', this[`${refKey}MouseleaveListener`])
          }
          if (!this.isMobile) addHoverListener('bannerSwiper')
          if (this.showCustomerSwiperArrow) addHoverListener('customerRatingSwiper')
        })
      }
    },
    isMobile () { // resize後需重新init
      this.$nextTick(() => {
        this.resetSwiper('bannerSwiper')
      })
    }
  },
  async mounted () {
    try {
      this.$store.commit('setWindowWidth', window.innerWidth)
      this.isLoading = false
    } catch (err) {
      console.log('err', err)
    }
  },
  beforeRouteLeave (to, from, next) {
    try {
      const removeHoverListener = (refKey) => {
        const ref = this.$refs?.[refKey]?.$el
        if (ref) {
          ref.removeEventListener('mouseover', this[`${refKey}MouseoverListener`])
          ref.removeEventListener('mouseleave', this[`${refKey}MouseleaveListener`])
          ref.swiper.destroy = () => {} // 防止destroys時slider的spacebetween被重置所導致的卡片位移
        }
      }
      removeHoverListener('bannerSwiper')
      removeHoverListener('customerRatingSwiper')
      this.$refs.recommendShareSwiper.swiper.destroy = () => {}
      next()
    } catch (err) {
      console.log('err', err)
    }
  },
  async asyncData ({ params, route, env, store, redirect }) {
    return {
      id: params.id,
      description: store.state.meta.description,
      routeFullPath: route.fullPath,
      webBaseUrl: String(env.BASE_WEB_URL || ' ').slice(0, -1)
    }
  },
  async fetch () {
    try {
      await this.$store.dispatch('getUserDefaultCommunity')
      await this.setBannerList()
      await Promise.all([
        this.setRecommendShareBlogList(),
        this.setCustomerRating(),
        this.setRecommendServiceList(),
        this.setRecommendedArticleList()
      ])
      if (this.$auth.loggedIn) {
        await this.$store.dispatch('checkIsGuestMember', { Phone: this.$auth.user.APhone })
      }
    } catch (err) {
      console.log('err', err)
    }
  },
  methods: {
    // api
    async setCustomerRating () {
      try {
        const { data } = await this.$axios.get('/api/v2/Main/PositiveRating', { useCache: true })
        this.customerRating.list = data.Data.Ratings.map(this.handleRatingDataFromAPI).slice(0, 20)
        this.customerRating.sliderOptions.loop = this.showCustomerSwiperArrow
        if (!this.showCustomerSwiperArrow) {
          this.customerRating.sliderOptions.autoplay = false
        }
      } catch (err) {
        console.log('err', err)
        return Promise.reject(err)
      }
    },
    async setBannerList () {
      try {
        const postData = { Tag: 'homepage' }
        const bannerList = await this.$store.dispatch('getBannerList', postData)
        this.banner.list = bannerList
          .map(banner => {
            banner.ImageUri = banner.img_uri
            return banner
          })
        this.banner.list = this.banner.list.splice(0, 6)
        this.banner.sliderOptions.loop = this.showBannerSwiperArrow
        if (!this.showBannerSwiperArrow) {
          this.banner.sliderOptions.autoplay = false
        }
        this.banner.loadDone = true
      } catch (err) {
        console.log(err)
        return Promise.resolve()
      }
    },
    async setRecommendServiceList () {
      try {
        const recommendServiceList = await this.$store.dispatch('service/getStarServiceList')
        this.recommendService.showSeeMoreBtn = recommendServiceList.length > 8
        this.recommendService.list = recommendServiceList.slice(0, 8)
      } catch (err) {
        console.log('err', err)
        this.recommendService.list = []
        return Promise.reject(err)
      }
    },
    async setRecommendedArticleList () {
      try {
        const postData = {
          MainTypeIds: this.recommendedArticle.aritcleMainTypeIds,
          'PagingSetting.Offset': 0,
          'PagingSetting.Count': 4
        }
        const response = await this.$axios.get('api/v1/Article/Banner', { params: postData, useCache: true })
        this.recommendedArticle.showSeeMoreBtn = response.data.Data.Articles.length >= 4
        this.recommendedArticle.list = this.formatArticleData(response.data.Data.Articles).slice(0, 4)
      } catch (err) {
        console.log('err', err)
        return Promise.reject(err)
      }
    },
    setRecommendShareBlogList () {
      this.recommendShare.list = _.shuffle(recommendShareBlog.recommendShareBlog)
    },
    formatArticleData (articleData) {
      return articleData.map(data => {
        data.CreateTime = this.$dayjs(data.CreateAt).format('YYYY/MM/DD')
        if (!data.CoverPictureUrl) {
          data.CoverPictureUrl = require('@/assets/image/article/default-cover.png')
        }
        return data
      })
    },
    handleRatingDataFromAPI (d, index) {
      d.tagList = d.Tags.slice(0, 3)
      const nameArray = Array.from(d.Username)
      let userName = ''
      for (let i = 0; i < nameArray.length; i++) {
        userName += i % 2 === 0 ? nameArray[i] : '＊'
      }
      return {
        id: d.ScoreId,
        userName,
        serviceId: d.ServiceId,
        serviceTitle: d.ServiceTitle,
        orderTime: this.$dayjs(new Date(d.PostTime)).format('YYYY/MM/DD'),
        score: d.Rating,
        scoreDesc: d.Comment.split('\n'),
        imgList: d.ImageUrls.slice(0, 3),
        tagList: d.tagList
          .map(tag => {
            return {
              id: index,
              name: tag
            }
          })
      }
    },
    clickBannerHandler (banner) {
      if (banner.link_uri) window.open(banner.link_uri, '_blank')
      this.$gaLogEvent('首頁', `click_banner_${banner.title}`)
    },
    // slider action
    clickBannerPaginationHandler (index) {
      this.$refs.bannerSwiper.swiper.slideToLoop(index, 200, false)
      this.banner.activeIndex = index
    },
    bannerSlideChangeHandler (refKey, datakey) {
      const { isBeginning, isEnd, realIndex } = this.$refs[refKey].swiper
      this[datakey].activeIndex = realIndex
      if (isBeginning || isEnd) {
        this.resetSwiper(refKey)
      }
    },
    resetSwiper (refKey) {
      this.$refs[refKey]?.swiper?.loopDestroy()
      this.$refs[refKey]?.swiper?.loopCreate()
      this.$refs[refKey]?.swiper?.update()
    },
    // route action
    goArticlePage (article) {
      this.$gaLogEvent('首頁', 'click_推薦文章')
      this.$nuxt.$router.push({ name: 'informationStation-article-idsn', params: { idsn: article.Id } })
    },
    async goServiceHandler () {
      this.$gaLogEvent('首頁', 'click_查看更多_熱門服務')
      this.$nuxt.$router.push({ name: 'index-id', params: { id: 'star', toTop: true } })
    },
    goServiceComment (rating) {
      this.$gaLogEvent('首頁', 'click_評價')
      this.$nuxt.$router.push({ name: 'category-category-service-comment', params: { service: rating.serviceId, category: 'star' } })
    },
    goInformationStationHandler () {
      this.$gaLogEvent('首頁', 'click_查看更多_推薦文章')
      this.$nuxt.$router.push({ name: 'informationStation', params: { toTop: true } })
    },
    openShareArticleHandler (sharePost) {
      this.$gaLogEvent('首頁', 'click_真實推薦分享')
      window.open(sharePost.url, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
$sectionMarginY: 60px;
$sectionTitleMarginBottom: 32px;
$sliderShadowPadding: 20px;
$articleCardMarginBottom: 20px;
$sectionMarginX: 5vw;
$contentContainerMaxWidth: calc(263px * 4 + 24px * 3); // 推薦服務區塊寬度限制
$contentContainerMaxWidthMargin: calc(263px * 4 + 24px * 3 + 100px);
$contentContainerMaxWidthMobile: calc(263px * 2 + 24px * 1);
$bannerHeightDesktop: calc(100vw * 380 / 1440);
$bannerHeightMobile: calc(100vw * 380 / 1048);
@mixin sectionLayoutDefined () {
  max-width: calc(#{$contentContainerMaxWidth} + #{$sectionMarginX} * 2);
  margin: $sectionMarginY auto 0;
  margin-bottom: $sectionTitleMarginBottom;
  padding: 0 $sectionMarginX;
  &__title {
    margin-bottom: $sectionTitleMarginBottom;
  }
  @media screen and (max-width: $mobileWidth) {
    max-width: calc(#{$contentContainerMaxWidthMobile} + #{$sectionMarginX} * 2);
  }
}
@mixin swiperSectionTitleDefined () {
  max-width: calc(#{$contentContainerMaxWidth} + #{$sectionMarginX} * 2);
  margin: 0 auto calc(#{$sectionTitleMarginBottom} - #{$sliderShadowPadding});
  padding: 0 $sectionMarginX;
  @media screen and (max-width: $mobileWidth) {
    // padding: 0;
    max-width: calc(#{$contentContainerMaxWidthMobile} + #{$sectionMarginX} * 2);
  }
}
@mixin seeMoreBtn () {
  display: flex;
  justify-content: center;
  &__btn {
    width: 360px;
    min-height: 48px;
    ::v-deep {
      .v-btn__content {
        font-weight: bold;
      }
    }
    i {
      font-size: 20px;
    }
    i::before {
      color: $mainColor;
    }
  }
  @media screen and (max-width: $mobileWidth) {
    margin: auto;
    &__btn {
      width: 269px;
    }
  }
}
::v-deep {
  $controlsMargin: 40px;
  .slider-container {
    display: block;
    width: 100%;
  }
  .swiper-pagination-custom, .swiper-pagination {
    display: flex;
    justify-content: center;
    bottom: 15px;
    width: 100%;
    left: 0;
    right: 0;
    position: absolute;
    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      &-active {
        background: $mainColor;
      }
    }
  }
  .swiper-pagination-btn {
    padding: 7px 15px;
    background-color: #fafafa;
    color: $fontColor;
    cursor: pointer;
    $btnRadius: 20px;
    transition: all 0.3s;
    &:first-child {
      border-radius: $btnRadius 0 0 $btnRadius;
    }
    &:last-child {
      border-radius: 0 $btnRadius $btnRadius 0;
    }
    &.one-child {
      border-radius: $btnRadius;
    }
    +.swiper-pagination-btn {
      margin-left: 1px;
    }
    &.active {
      background-color: $mainColor;
      color: $whiteColor;
    }
  }
  .homepage-loader {
    &__banner {
      height: $bannerHeightDesktop;
      .v-skeleton-loader__image {
        height: 100%;
      }
    }
    &__card-container {
      @include sectionLayoutDefined;
      display: flex;
      flex-wrap: wrap;
      &__card {
        $cardMargin: 24px;
        width: calc((100% - #{$cardMargin} * 3) / 4);
        margin-bottom: $cardMargin;
        height: initial;
        &:not(:nth-child(4n)) {
          margin-right: $cardMargin;
        }
      }
    }
  }
}
.page-homepage {
  width: 100%;
}
.homepage-banner-swiper {
  width: 100%;
  height: $bannerHeightDesktop;
  &-container {
    position: relative;
    overflow: hidden;
  }
  &__image {
    height: $bannerHeightDesktop;
    object-fit: cover;
    width: 100vw;
    &:hover {
      cursor: pointer;
    }
  }
}
.swiper-arrow-controls {
  .btn {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    box-shadow: 0px 4px 8px 1px rgb(75 88 105 / 12%);
    z-index: 11;
    cursor: pointer;
    background: #fff;
    &.v-btn:hover:before { // 移除focus樣式
      opacity: 0 !important;
    }
    @media (hover: hover) {
      &:hover {
        transform: translateY(-50%) scale(1.3);
        transition: all 100ms ease-in-out;
      }
    }
    &.prev {
      left: -60px;
    }
    &.next {
      right: -60px;
    }
    i {
      font-size: pxTorem(20px);
      &::before {
        color: #484848;
      }
    }
  }
  &.inside {
    .prev {
      left: 60px;
    }
    .next {
      right: 60px;
    }
  }
  &.rating, &.recommend-share {
    .prev {
      left: calc(((100vw - #{$contentContainerMaxWidth}) / 2) - 70px);
    }
    .next {
      right: calc(((100vw - #{$contentContainerMaxWidth}) / 2) - 70px);
    }
  }
}
.card-swiper-container { // 卡片swiper
  width: 100%;
  margin-bottom: $sectionMarginY;
  min-height: 300px;
  .v-lazy {
    height: 100%;
  }
  @mixin pseudo-mask-background($direction) {
    content: '';
    width: calc(((100vw - #{$contentContainerMaxWidth}) / 2) + 20px);
    background-image: linear-gradient($direction, transparent, #fff);
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10;
  }
  .swiper-container {
    position: relative;
    &::after {
      @include pseudo-mask-background(to right);
      right: 0;
    }
    &::before {
      @include pseudo-mask-background(to left);
      left: 0;
    }
  }
  ::v-deep {
    .swiper-slider {
      align-items: stretch;
    }
    .swiper-slide {
      transform: translate3d(calc((100vw - #{$contentContainerMaxWidth}) / 2), 0, 0);
      margin: $sliderShadowPadding 0;
      height: initial;
    }
  }
}
.recommend-service-section { // 推薦服務
  @include sectionLayoutDefined;
  &__see-more {
    @include seeMoreBtn;
  }
}
.customer-rating-section { // 用戶好評
  margin-top: $sectionMarginY;
  &__title {
    @include swiperSectionTitleDefined;
  }
  .customer-rating-card-container {
    ::v-deep {
      .swiper-slide {
        z-index: 1000;
        width: 420px;
      }
    }
  }
}
.smartdaily-intro-section { // 官網介紹
  width: 100%;
  background: #f9f9f9;
  .smartdaily-intro {
    width: calc(100% / 4);
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $fontColor;
    text-align: center;
    padding: 42px 0;
    &__container {
      @include sectionLayoutDefined;
      margin-top: 0;
      display: flex;
      flex-wrap: wrap;
    }
    &__img {
      width: 80px;
      height: 80px;
      margin-bottom: 20px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    &__title {
      font-size: pxTorem(21px);
      font-weight: bold;
      margin-bottom: 12px;
      p {
        margin-bottom: 0;
      }
    }
    &__desc {
      p {
        margin-bottom: 4px;
      }
    }
  }
}
.recommend-article-section { // 推薦文章
  @include sectionLayoutDefined;
  &__title {
    margin-bottom: calc(#{$sectionTitleMarginBottom} - 30px);
  }
  &__articles-container {
    display: flex;
    flex-wrap: wrap;
  }
  &__article {
    $articleCardMarginRight: 30px;
    width: calc((100% - #{$articleCardMarginRight}) / 2);
    padding-bottom: $articleCardMarginBottom;
    padding-top: $articleCardMarginBottom;
    &:not(:nth-child(2n)) {
      margin-right: $articleCardMarginRight;
    }
    &:not(:nth-last-child(-n + 2)) {
      border-bottom: 1px solid #eaeaea;
    }
  }
  &__see-more {
    @include seeMoreBtn;
    margin-top: 28px;
  }
}
.recommend-share-section { // 部落客分享
  margin-top: $sectionMarginY;
  &__title {
    @include swiperSectionTitleDefined;
  }
  .recommend-share-card-container {
    ::v-deep {
      .swiper-slide {
        width: 348px;
        &.fake-slide {
          width: calc(100vw - #{$contentContainerMaxWidth});
        }
      }
    }
  }
}
.homepage-section-spacer {
  @include sectionLayoutDefined;
  height: 8px;
  background-color: #efeff4;
  padding: 0;
  max-width: $contentContainerMaxWidth;
  @media screen and (max-width: $mobileWidth) {
    margin-top: calc(#{$sectionMarginY} - #{$articleCardMarginBottom});
    max-width: $contentContainerMaxWidthMobile;
  }
}
/** 手機 */
@media screen and (max-width: $mobileWidth) {
  .swiper-arrow-controls {
    .btn {
      width: 24px;
      height: 24px;
    }
    &.rating, &.recommend-share {
      .prev {
        left: calc(#{$sectionMarginX} - 12px);
      }
      .next {
        right: calc(#{$sectionMarginX} - 12px);
      }
    }
    &.inside {
      .prev {
        left: 20px;
      }
      .next {
        right: 20px;
      }
    }
  }
  .homepage-banner-swiper {
    width: 100vw;
    height: $bannerHeightMobile;
    &__image {
      height: $bannerHeightMobile;
    }
  }
  .card-swiper-container {
    ::v-deep {
      .swiper-slide {
        transform: translate3d($sectionMarginX, 0, 0);
        &.fake-slide {
          width: calc(#{$sectionMarginX} + 40px);
        }
      }
    }
  }
  .smartdaily-intro-section {
    .smartdaily-intro {
      width: calc(100% / 2);
    }
  }
  .recommend-article-section {
    &__article {
      $articleCardMarginRight: 60px;
      width: 100%;
      &:not(:nth-child(2n)) {
        margin-right: 0;
      }
      &:not(:last-child) {
        border-bottom: 1px solid #eaeaea;
      }
    }
  }
  .customer-rating-section .customer-rating-card-container,
  .recommend-share-section .recommend-share-card-container {
    ::v-deep {
      .swiper-slide {
        width: 268px;
        &.fake-slide {
          width: calc(#{$sectionMarginX} + 40px);
        }
      }
    }
  }
  ::v-deep {
    .swiper-pagination-btn {
      padding: 0;
      width: 8px;
      height: 8px;
      cursor: pointer;
      border-radius: 50%;
      margin-right: 8px;
      &:first-child {
        border-radius: 50%;
      }
      &:last-child {
        border-radius: 50%;
      }
      &__text {
        display: none;
      }
    }
    .homepage-loader {
      &__banner {
        height: $bannerHeightMobile;
      }
      &__card-container__card {
        $cardMargin: 16px;
        width: calc((100% - #{$cardMargin}) / 2);
        margin-bottom: $cardMargin;
        &:not(:nth-child(4n)) {
          margin-right: 0;
        }
        &:not(:nth-child(2n)) {
          margin-right: $cardMargin;
        }
      }
    }
  }
}
@media screen and (min-width: $mobileWidth) and (max-width: $contentContainerMaxWidthMargin) {
  .swiper-arrow-controls {
    &.rating, &.recommend-share {
      .prev {
        left: calc(#{$sectionMarginX} - 40px);
      }
      .next {
        right: calc(#{$sectionMarginX} - 40px);
      }
    }
  }
  .card-swiper-container {
    ::v-deep {
      .swiper-slide {
        transform: translate3d($sectionMarginX, 0, 0);
      }
    }
  }
  .recommend-share-section .recommend-share-card-container {
    ::v-deep {
      .swiper-slide {
        &.fake-slide {
          width: calc(#{$sectionMarginX} * 2 + 40px);
        }
      }
    }
  }
}
</style>
